<template> 
    <b-modal ref="modal-add" size="md" v-model="computedShow" no-close-on-backdrop :title="trans('ss-add-suspect',169)">
        
        <ValidationObserver ref="form" >
            <b-form @submit.prevent="onSubmit">
                <app-divider-modal :text="trans('organization',169)" />

                <app-row-left-label :label="trans('organization_name',169)">
                    <app-input v-model="client_data.name" validatorName="Organisatienaam" validatorRules="required" mask="lettersE9-40"/>
                </app-row-left-label>

                <app-address-nl ref="address_control" :address.sync="client_data.address" />

                <app-kvk ref="kvk_control" :kvkData.sync="client_data.kvk_data" :label="trans('kvk_number',169)" :checkIfExists="true" :validatorRules="'required'"/>

                <app-divider-modal :text="trans('contactperson',169)" :isLine="true"/>
       
                <app-row-left-label :label="trans('first-name',182)">
                    <app-input v-model="client_data.first_name" validatorName="Voornaam" :validatorRules="isRequired ? 'required' : ''" mask="lettersE1-20"/>
                </app-row-left-label>    

                <app-row-left-label :slots="[4,8]" :label="trans('last-name',182)">
                    <template v-slot:col-1>
                        <app-input v-model="client_data.inbetween_name" :placeholder="trans('inbetween-name',182)" mask="lettersE1-10"/>
                    </template>
                     <template v-slot:col-2>
                        <app-input v-model="client_data.last_name" :placeholder="trans('last-name',182)" validatorName="Achternaam" :validatorRules="isRequired ? 'required' : ''" mask="lettersE1-30" />
                    </template>
                </app-row-left-label>

                <app-row-left-label :label="trans('function',3)">
                    <app-input v-model="client_data.function" validatorName="Functie" :validatorRules="isRequired ? 'required' : ''" mask="lettersE1-30"/>
                </app-row-left-label>

                <app-row-left-label :label="trans('phone-number',182)">
                    <app-phone :prefix.sync="client_data.phone_prefix" :phone.sync="client_data.phone_number" :isRequired="isRequired ? true : false"/>
                </app-row-left-label>

                 <app-row-left-label :label="trans('email',182)">
                    <app-email :email.sync="client_data.email" validatorName="Email" :validatorRules="isRequired ? 'required' : ''" type="person" />
                </app-row-left-label>

                <app-divider-modal :text="trans('happynurse-data',3)" :isLine="true"/>

                 <app-row-left-label :label="trans('branch',182)" >
                    <app-select v-model="client_data.location" type="getUserLocations" :disable="false" clearable/>
                </app-row-left-label>

                <app-row-left-label :label="trans('contactperson',169)" >
                    <app-select v-model="client_data.contact_user" :refreshIndex.sync="refresh_users" :args.sync="users_args" type="getUsersFromLocation" :disable="users_args.id_office_data_location < 1" validatorName="Recruiter" validatorRules="required" />
                </app-row-left-label>

            </b-form>
        </ValidationObserver> 
        
        <template #modal-footer>
            <app-button @click="closeModal" type="cancel" >{{trans('cancel',175)}}</app-button>
            <app-button type="primary" @click="clientAdd" :disabled="loading" :loading="loading">
                {{trans('add',175)}}
            </app-button>
        </template>
    </b-modal>
    
</template>

<script>

// #ID  - PMI 2022-01-25

import axios from "@axios";
import internationals_json from "@/assets/json/internationals.json";
import { ValidationObserver } from "vee-validate";
import AppAddress from '@core/components/Address/AppAddress.vue';
import AppPhone from '@core/components/AppPhone.vue';
import AppEmail from '@core/components/AppEmail.vue';
import AppAddressNl from '@core/components/Address/AppAddressNl.vue';
import AppKvk from "@core/components/AppKvk"

export default {
    components: {
        ValidationObserver,
        AppAddressNl,
        AppPhone,
        AppEmail,
        AppKvk
    },

    props: ["show"],

    created(){

        this.user_data = JSON.parse(localStorage.getItem('user'));
        var user_locations = JSON.parse(localStorage.getItem("USER_LOCATIONS"));

        if(this.user_data != null){
            this.client_data.contact_user = {name: this.user_data.user_name, value: this.user_data.id}
        }

        if(user_locations.length == 1){
            this.client_data.location = {name: user_locations[0].name, value: user_locations[0].value}
            this.users_args.id_office_data_location = this.client_data.location.value;
        }

    },

    watch: {
        "client_data.location": {
            handler: function() {
                if(this.client_data.location !== null){
                    this.users_args.id_office_data_location = this.client_data.location.value;

                    if(this.client_data.contact_user.value != this.user_data.id){
                        this.client_data.contact_user.name = "";
                        this.client_data.contact_user.value = null;
                        this.client_data.contact_user = null;
                    }
                }
                this.refresh_users++;
            }
  
        }
    },

     computed: {
        computedShow: {
        get() { return this.show },
        set() { this.$emit('update:show', false) }, 
        }
    },

    data() {
        return {
            loading: false,
            client_data:{
                name: "",
                address: {
                                type: 1,
                                post_code: "", 
                                house_number: "", 
                                house_number_additional:"",
                                city:"",
                                street:"",
                                latitude: null,
                                longitude: null
                            },
                function:null,
                first_name: null,
                inbetween_name:"",
                last_name: null,
                phone_prefix: '+31',
                phone_number: null,
                email: null,
                location: null,
                contact_user: null,
                kvk_data: {
                    kvk_number: null,
                    kvk_data_main: null,
                }
            },
            users_args: {id_office_data_location: null},
            refresh_users: 1,
            user_data: null,
            isRequired: false,

            internationals: internationals_json,
        };
    },

    methods: {

        async clientAdd() {
            
            this.$refs.form.validate().then((result) => {
                if((this.client_data.first_name || this.client_data.last_name || this.client_data.function || this.client_data.phone_number || this.client_data.email) && 
                    (!this.client_data.first_name || !this.client_data.last_name || !this.client_data.function || !this.client_data.phone_number || !this.client_data.email)){
                    this.isRequired = true;
                    return false;
                }else{
                    this.isRequired = false;
                    if(this.client_data.name && this.client_data.location){
                        result = true;
                    }
                }
                
                if (!result) {
                    return false;
                }
               else{
                    if(!this.$refs.address_control.address.street && this.client_data.address.post_code){
                        return false;
                    }else{
                        this.loading = true;

                        axios
                            .post("clients/suspect/add", {
                                client_data: JSON.stringify(this.client_data),
                            })
                            .then((res) => {
                                
                                if(res.data == -1){
                                    this.$refs.form.setErrors({
                                        email: [this.trans('email-exist-error',191)]
                                    });
                                    this.loading = false;
                                    return false;
                                }
                                this.$emit("update:show", false);
                                this.loading = false;
                                this.$router.push({ name: "clients-profile-organization", params: { id_client_data_main: res.data } });
                            })
                            .catch((error) => {
                                console.error(`error during request: ${error}`);
                            });
                    }
                        
                }
            })
                
        },

        closeModal() {
            this.$emit("update:show", false);
        },

    },
};
</script>

<style>

</style>
