<template>
    <b-modal ref="modal-add" size="md" v-model="computedShow" no-close-on-backdrop :title="trans('enrollment',3)" @shown="modalShown">
        <ValidationObserver ref="form" >
            <b-form @submit.prevent="onSubmit">
                <app-row-left-label :label="trans('branch',182)">
                    <app-select v-model="cArgs.location" :refreshIndex.sync="refresh_offices" type="getUserLocations" validatorRules="required" validatorName="Vestiging" @loaded="setOffice"/>
                </app-row-left-label>

                <app-row-left-label :label="trans('contactperson',169)">
                    <app-select v-model="cArgs.contact_person" :refreshIndex.sync="refresh_contact_person" :args.sync="users_args" type="getUsersFromLocation" :disable="users_args.id_office_data_location < 1" validatorRules="required" validatorName="Contactpersoon" /> 
                </app-row-left-label>

                <app-row-left-label :label="trans('cp-account-manager',192)">
                    <app-select  v-model="cArgs.account_manager" type="getAccountManagersList" :refreshIndex.sync="refresh_users" validatorRules="required" validatorName="Accountmanager" />
                </app-row-left-label>
            </b-form>
        </ValidationObserver>

        <template #modal-footer>

                <app-button type="cancel" @click="closeModal">{{trans('cancel',175)}}</app-button>
                <app-button type="primary" @click="change" :loading="loading">
                    {{trans('save',175)}}
                </app-button>
        </template>
    </b-modal>
</template>

<script>
import axios from "@axios";
import { ValidationObserver } from "vee-validate";

export default {
    components: {
        ValidationObserver
    },

    props: ["show", "args", "return", "result"],

    created(){
        if(this.args.account_manager.value == null){
            this.cArgs.account_manager = null;
        }
        if(this.args.location != null){
            this.users_args.id_office_data_location = this.args.location.value;
        }
        if(this.args.contact_person.value == null){
            this.cArgs.contact_person = null;
        }
    },

    watch: {
        "cArgs.location": {
            handler: function(val) {
                if(val != null ){
                    this.users_args.id_office_data_location = this.args.location.value;
                    if(this.cArgs.contact_person != null && !JSON.parse(val.users).includes(this.cArgs.contact_person.value) ){
                        this.cArgs.contact_person.name = null;
                        this.cArgs.contact_person.value = null;
                        this.cArgs.contact_person = null;
                    }
                    // if(this.cArgs.account_manager != null){
                    //     this.cArgs.account_manager.name = null;
                    //     this.cArgs.account_manager.value = null;
                    //     this.cArgs.account_manager = null;
                    // }

                    // this.cArgs.contact_person.name = null;
                    // this.cArgs.contact_person.value = null;
                    // this.cArgs.contact_person = null;

                    //this.refresh_users++;
                    this.refresh_contact_person++;
                }
            },
            deep: true,
        }
    },

    computed: {
        computedShow: {
            get() { return this.show },
            set(value) { this.$emit('update:show', value) }, 
        },
        cReturn: {
            get() { return this.return },
            set(value) { this.$emit('update:return', value) }, 
        },
        cArgs: {
            get() { return this.args },
            set(value) { this.$emit('update:args', value) }, 
        },
    },


    data() {
        return {
            loading: false,
            refresh_users: 1,
            refresh_contact_person: 1,
            refresh_offices: 1,
            users_args: {id_office_data_location: null},
        };
    },

    methods: {
        change() {
   
            this.$refs.form.validate().then((result) => {
                if (!result) {
                    return false;
                }
                else{
                    this.loading = true;
                    axios
                        .post("clients/suspect/change", {
                            id_client_data_main: this.args.id_client_data_main,
                            account_manager: JSON.stringify(this.args.account_manager),
                            user_contact: JSON.stringify(this.args.contact_person),
                            location: JSON.stringify(this.args.location),
                            filters: JSON.stringify(this.args.filters)
                        })
                        .then((res) => {
                            this.cReturn = res.data.items;
                            this.cArgs.filters.page = res.data.page;
                        })
                        .catch((error) => {
                            console.error(`error during request: ${error}`);
                        })
                        .finally(() => {
                            this.loading = false;
                            this.computedShow = false;
                        })
                }
            })
            
        },

        setOffice(event){
            var user_locations = JSON.parse(localStorage.getItem("USER_LOCATIONS"));
            if(user_locations.length == 1){
                var office = event.filter(i => i.value == user_locations[0].value)
                if( office.length == 1 ){

                    this.cArgs.location = office[0];
                    
                    this.users_args.id_office_data_location = this.cArgs.location.value;

                } else {
                    this.cArgs.contact_person = null;
                }
            }
        },

        modalShown(){
            this.refresh_offices++;
        },

        closeModal() {
            this.$emit("update:show", false);
        },

    },
};
</script>

<style scoped>
</style>
