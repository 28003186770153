<template>
    <div>
        <b-card>
            <app-view-table title="Suspects" :loading="loading_list" :isTitleTotal="true" :filters.sync="filters" :items="items" v-on:onRowSelected="getProfile" :fields="fields" :canUserEdit="user_role < 3" :filterOptions="suspect_status_list" :isSearch="true" :isFilterOptions="true" class="table-selectable">
                
                 <template v-slot:menu-items="cell">
                    <b-dropdown-item @click="startQuotationPhase(cell.data.item)" >{{trans('cp-start-quotation-phase',189)}}</b-dropdown-item>
                </template>

                <template v-slot:buttons>
                    <app-button type="primary" @click="mSuspectAdd.show = true">{{trans('ss-add-suspect',169)}}</app-button>
                </template>
            </app-view-table>

            <clients-suspect-add v-if="mSuspectAdd.show" :show.sync="mSuspectAdd.show"/> 
            <client-suspect-list-change v-if="mRegistrationChange.show" :show.sync="mRegistrationChange.show" :args.sync="mRegistrationChange.args" :result.sync="mRegistrationChange.result" :return.sync="items" />   
            
        </b-card> 
    </div>
</template>
<script>

import axios from "@axios";
import ClientsSuspectAdd from '@app/Clients/Suspect/List/ClientsSuspectAdd.vue';
import ClientSuspectListChange from './Suspect/List/ClientSuspectListChange.vue';
import AppAlerts from '@core/scripts/AppAlerts';
import UserSettings from '@core/scripts/UserSettings';

export default {
    components: {
        ClientsSuspectAdd,
        ClientSuspectListChange
    },

    mounted() {
        this.onCreateThis();
    },

    data() {
        return {
            loading_list: false,
            filters: {
                sorting: {
                        field_name: null,
                        sort_direction: 0,
                        changed: 0
                },
                filtering: {  
                    locations: {type: 'list',  changed: 0, values: [], function: "getLocations", parameters: null, widthClass:'app-width-min-250'},
                    statuses: {type: 'list', changed: 0, values: [], function: "getClientStatuses", parameters: null, widthClass:'app-width-min-250'},                      
                    locations_address: {type: 'list',  changed: 0, values: [], function: "getLocationsAddress", parameters: null, widthClass:'app-width-min-250'},
                },
                page: 1,
                per_page: 10,
                search: "",
                list_type: 1,
                changed: 0
            },
            fields: [
                { key: "client_name", label: this.trans('organization',169), thStyle: { "min-width": "130px", width: "100%" }, visible: true},
                { key: "location_address", label: this.trans('location',3), thStyle: { "min-width": "400px", "max-width": "400px" }, visible: true, filtering: true, filtering_name: "locations_address"  },
                { key: "location_name", label: this.trans('branch',182), thStyle: { "min-width": "150px", "max-width": "200px" }, visible: true, filtering: true, filtering_name: "locations"  },
                { key: "status_name", label: this.trans('status',3), thStyle: { "min-width": "150px", "max-width": "200px" }, visible: true, filtering: true, filtering_name: "statuses"  }, 
            ],
            items: [],
            mSuspectAdd: { show: false, args: null, result: 0, return: null },
            suspect_status_list: [
                { value: 1, name: this.trans('my-locations',179) },
                { value: 2, name: this.trans('my-suspects',188) },               
                { value: -1, name: ""},
                { value: 5, name: this.trans('all-suspects',188) },
            ],
            alertClass: new AppAlerts(),
            userSettings: new UserSettings(),
            user_role: JSON.parse(localStorage.getItem('user')).id_user_const_role,
            mRegistrationChange: { show: false, args: {
                id_client_data_main:null,
                account_manager: {value: null, name: null},
                contact_person: {value: null, name: null},
                location: {value: null, name: null},
                filters: null
            }, result: 0, return: null },

        };
    },

    methods: {
        getList: function() {
            
            this.loading_list = true;
            axios
                .post("clients/suspect/getList", {
                    filters: JSON.stringify(this.filters),
                })
                .then((res) => {
                    this.filters.page = res.data.page;
                    this.items = res.data.items;
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                }).finally(() => {
                    this.loading_list = false;
                });
        },

        getProfile: function(selectedRows) {
            if(selectedRows.length != 0){
                this.$router.push({ name: "clients-profile-organization", params: { id_client_data_main: selectedRows[0].id_client_data_main } });
            }
        },

        saveSettings() {
            this.userSettings.saveSettings(this.filters, 9);
        },

        async loadSettings() {
            var getData = JSON.parse(localStorage.getItem('userSettings'));

            if(getData == undefined || getData.suspectsList == undefined){
                await this.userSettings.saveSettings(this.filters, 9);
                getData = JSON.parse(await localStorage.getItem('userSettings'));
            }
                
            var sFilters = getData.suspectsList;

            this.filters.list_type = sFilters.list_type;
            this.filters.filtering = sFilters.filtering;
            this.filters.sorting = sFilters.sorting;     
            this.filters.per_page = sFilters.per_page != undefined ? sFilters.per_page : 10;  
        },

        rejectSuspect: function( data ) {
            if(data.status_name == "Ingetrokken"){
                this.alertClass.openAlertInfo(this.trans('applicant-no-suitable-status-error',180)).then(res => {
                }); 
            }else{
                this.alertClass.openAlertConfirmation(this.trans('revoke-suspect-confirmation', 191, {client_name : data.client_name})).then(res => {
                    if(res == true){
                        this.loading_list = true;
                        axios
                            .post("clients/suspect/reject", {
                                id_client_data_main: data.id_client_data_main,
                                filters: JSON.stringify(this.filters),
                            })
                            .then((res) => {
                                this.filters.page = res.data.page
                                this.items = res.data.items;
                            })
                            .catch((error) => {
                                console.error(`error during request: ${error}`);
                            })
                                .finally(() => {
                                this.loading_list = false;
                            });;
                    }     
                }); 
            }
        },
        deleteSuspect: function( data ) {
            if(data.status_name == "Afgewezen"){
                this.alertClass.openAlertInfo(this.trans('applicant-no-suitable-status-error',180)).then(res => {
                }); 
            }else{
                this.alertClass.openAlertConfirmation(this.trans('reject-suspect-confirmation', 191, {client_name : data.client_name})).then(res => {
                    if(res == true){
                        this.loading_list = true;
                        axios
                            .post("clients/suspect/delete", {
                                filters: JSON.stringify(this.filters),
                                id_client_data_main: data.id_client_data_main,
                            })
                            .then((res) => {
                                this.filters.page = res.data.page
                                this.items = res.data.items;
                            })
                            .catch((error) => {
                                console.error(`error during request: ${error}`);
                            }).finally(() => {
                                this.loading_list = false;
                            });;
                    }     
                }); 
            }
        },

        startQuotationPhase(item){
            if(item.id_office_data_location == null){
                this.change(item)

            } else {
                this.loading_list = true;
                axios
                    .post("clients/suspect/startQuotationPhase", {
                        filters: JSON.stringify(this.filters),
                        id_client_data_main: item.id_client_data_main,
                    })
                    .then((res) => {
                        this.filters.page = res.data.page
                        this.items = res.data.items;
                    })
                    .catch((error) => {
                        console.error(`error during request: ${error}`);
                    }).finally(() => {
                        this.loading_list = false;
                    });
            }
        },

        change(item){
            this.mRegistrationChange.args.id_client_data_main = item.id_client_data_main;
            this.mRegistrationChange.args.location.value = item.id_office_data_location;
            this.mRegistrationChange.args.location.name = item.office_name;
            this.mRegistrationChange.args.account_manager = {value: item.id_user_manager, name: item.account_manager_name}
            this.mRegistrationChange.args.contact_person = {value: item.id_user_contact, name: item.contact_person_name}
            this.mRegistrationChange.args.filters = this.filters;

            this.mRegistrationChange.show = true;
        },

        onCreateThis() { 
            this.loadSettings();

            this.getList();

            this.$root.$on('locationsChanged', () => {
        
                if (!this.loading_list) {
                    this.getList();
                } 
            });

            this.$watch('filters.list_type', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                    this.saveSettings()
                } 
            });

            this.$watch('filters.sorting.changed', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                    this.saveSettings()
                } 
            });

            this.$watch('filters.changed',function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                    this.saveSettings()
                } 
            });

            this.$watch('filters.search', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.filters.page = 1;
                    this.getList();
                } 
            });

            this.$watch('filters.page', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                }   
            });

            this.$watch('items.refresh', function(newVal, oldVal) {
                if (newVal > 0 && !this.loading_list) {
                    this.getList();
                }   
            });
            this.$watch('filters.filtering.statuses.changed', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                    this.saveSettings()
                } 
            });

            this.$watch('filters.filtering.locations_address.changed', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                    this.saveSettings()
                } 
            });

            this.$watch('filters.filtering.locations.changed', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                    this.saveSettings()
                } 
            });
        },

    },

    

    
};
</script>